import actions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  notifications: [],
  total: 0,
  modalActive: false,
  notification: {},
};

export default function reducer(
  state = initState,
  { type, payload }
) {
  switch (type) {
    case actions.LOAD_NOTIFICATIONS:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
    case actions.LOAD_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        notifications: payload.data.notifications ? payload.data.notifications : [],
        total: payload.data.total ? payload.data.total : 0,
        errorMessage: false,
      };
    case actions.LOAD_NOTIFICATIONS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'There is a loading problem',
      };
    case actions.TOGGLE_NOTIFICATION_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        notification: payload.data == null ? initState.notification : payload.data,
      };
    case actions.UPDATE_NOTIFICATION:
      return {
        ...state,
        notification: payload.data,
      };
    case actions.SAVE_NOTIFICATION:
      return {
        ...state,
        isLoading: true
      };
    case actions.SAVE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: false,
      };
    case actions.SAVE_NOTIFICATION_ERROR:
      return {
        ...state,
        errorMessage: 'Error in save notification',
        isLoading: false
      };
    default:
      return state;
  }
}
