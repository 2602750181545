const actions = {
  LOAD_USERS: 'LOAD_USERS',
  LOAD_USERS_SUCCESS: 'LOAD_USERS_SUCCESS',
  LOAD_USERS_ERROR: 'LOAD_USERS_ERROR',
  USER_DETAIL: 'USER_DETAIL',
  USER_DETAIL_SUCCESS: 'USER_DETAIL_SUCCESS',
  USER_DETAIL_ERROR: 'USER_DETAIL_ERROR',
  SAVE_USER: 'SAVE_USER',
  SAVE_USER_ERROR: 'SAVE_USER_ERROR',
  SAVE_USER_SUCCESS: 'SAVE_USER_SUCCESS',
  TOGGLE_USER_MODAL: 'TOGGLE_USER_MODAL',
  UPDATE_USER: 'UPDATE_USER',
  APPROVE_USER: 'APPROVE_USER',
  APPROVE_USER_SUCCESS: 'APPROVE_USER_SUCCESS',
  APPROVE_USER_ERROR: 'APPROVE_USER_ERROR',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_ERROR: 'CHANGE_PASSWORD_ERROR',
  LOAD_USER_MEASUREMENTS: 'LOAD_USER_MEASUREMENTS',
  LOAD_USER_MEASUREMENTS_SUCCESS: 'LOAD_USER_MEASUREMENTS_SUCCESS',
  LOAD_USER_MEASUREMENTS_ERROR: 'LOAD_USER_MEASUREMENTS_ERROR',
  SAVE_USER_MEASUREMENT: 'SAVE_USER_MEASUREMENT',
  SAVE_USER_MEASUREMENT_ERROR: 'SAVE_USER_MEASUREMENT_ERROR',
  SAVE_USER_MEASUREMENT_SUCCESS: 'SAVE_USER_MEASUREMENT_SUCCESS',

  loadUsers: (data) => {
    return { type: actions.LOAD_USERS, payload: { data }, };
  },

  loadUsersSuccess: data => ({
    type: actions.LOAD_USERS_SUCCESS,
    payload: { data },
  }),

  loadUsersError: error => ({
    type: actions.LOAD_USERS_ERROR,
    payload: { error },
  }),

  userDetails: (data) => {
    return { type: actions.USER_DETAIL, payload: { data }, };
  },

  toggleModal: (data = null) => ({
    type: actions.TOGGLE_USER_MODAL,
    payload: { data },
  }),

  saveUser: (data, actionName = 'insert') => ({
    type: actions.SAVE_USER,
    payload: { data, actionName },
  }),

  saveUserSuccess: () => ({
    type: actions.SAVE_USER_SUCCESS
  }),

  saveUserError: error => ({
    type: actions.SAVE_USER_ERROR,
    payload: { error },
  }),

  update: (data) => ({
    type: actions.UPDATE_USER,
    payload: { data },
  }),

  approveUser: (data) => ({
    type: actions.APPROVE_USER,
    payload: { data },
  }),

  approveUserSuccess: () => ({
    type: actions.APPROVE_USER_SUCCESS
  }),

  approveUserError: error => ({
    type: actions.APPROVE_USER_ERROR,
    payload: { error },
  }),
  changePassword: (data) => ({
    type: actions.CHANGE_PASSWORD,
    payload: { data },
  }),

  changePasswordSuccess: () => ({
    type: actions.CHANGE_PASSWORD_SUCCESS
  }),

  changePasswordError: error => ({
    type: actions.CHANGE_PASSWORD_ERROR,
    payload: { error },
  }),

  loadUserMeasurements: (data) => {
    return { type: actions.LOAD_USER_MEASUREMENTS, payload: { data }, };
  },

  loadMeasurementsSuccess: data => ({
    type: actions.LOAD_USER_MEASUREMENTS_SUCCESS,
    payload: { data },
  }),

  loadMeasurementsError: error => ({
    type: actions.LOAD_USER_MEASUREMENTS_ERROR,
    payload: { error },
  }),

  saveUserMeasurement: (data, actionName = 'insert') => ({
    type: actions.SAVE_USER_MEASUREMENT,
    payload: { data, actionName },
  }),

  saveUserMeasurementSuccess: () => ({
    type: actions.SAVE_USER_MEASUREMENT_SUCCESS
  }),

  saveUserMeasurementError: error => ({
    type: actions.SAVE_USER_MEASUREMENT_ERROR,
    payload: { error },
  }),
};
export default actions;
