import { combineReducers } from 'redux';
import App from '@iso/redux/app/reducer';
import Auth from '@iso/redux/auth/reducer';
import Ecommerce from '@iso/redux/ecommerce/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import modal from '@iso/redux/modal/reducer';
import Users from '@iso/redux/users/reducers';
import Contacts from '@iso/redux/contacts/reducers';
import Banners from '@iso/redux/banners/reducers';
import Categories from '@iso/redux/categories/reducers';
import Products from '@iso/redux/products/reducers';
import Notifications from '@iso/redux/notifications/reducers';
import Settings from '@iso/redux/settings/reducers';
import Dashboard from '@iso/redux/dashboard/reducers';

export default combineReducers({
  Users,
  Contacts,
  Banners,
  Categories,
  Products,
  Notifications,
  Settings,
  Dashboard,
  Auth,
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  Ecommerce,
  modal
});
