import actions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  banners: [],
  total: 0,
  modalActive: false,
  banner: {visible: true},
};

export default function reducer(
  state = initState,
  { type, payload }
) {
  switch (type) {
    case actions.LOAD_BANNERS:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
    case actions.LOAD_BANNERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        banners: payload.data.banners ? payload.data.banners : [],
        total: payload.data.total ? payload.data.total : 0,
        errorMessage: false,
      };
    case actions.LOAD_BANNERS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'There is a loading problem',
      };
    case actions.TOGGLE_BANNER_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        banner: payload.data == null ? initState.banner : payload.data,
      };
    case actions.UPDATE_BANNER:
      return {
        ...state,
        banner: payload.data,
      };
    case actions.SAVE_BANNER:
      return {
        ...state,
        isLoading: true
      };
    case actions.SAVE_BANNER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: false,
      };
    case actions.SAVE_BANNER_ERROR:
      return {
        ...state,
        errorMessage: 'Error in save banner',
        isLoading: false
      };
    default:
      return state;
  }
}
