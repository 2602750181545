const actions = {
  LOAD_DASHBOARD: 'LOAD_DASHBOARD',
  LOAD_DASHBOARD_SUCCESS: 'LOAD_DASHBOARD_SUCCESS',
  LOAD_DASHBOARD_ERROR: 'LOAD_DASHBOARD_ERROR',
  GET_EXERCISES_STATISTICS: 'GET_EXERCISES_STATISTICS',
  GET_EXERCISES_STATISTICS_SUCCESS: 'GET_EXERCISES_STATISTICS_SUCCESS',
  GET_EXERCISES_STATISTICS_ERROR: 'GET_EXERCISES_STATISTICS_ERROR',
  GET_EXERCISES_BY_USERID: 'GET_EXERCISES_BY_USERID',
  GET_EXERCISES_BY_USERID_SUCCESS: 'GET_EXERCISES_BY_USERID_SUCCESS',
  GET_EXERCISES_BY_USERID_ERROR: 'GET_EXERCISES_BY_USERID_ERROR',

  loadDashboard: (data) => {
    return { type: actions.LOAD_DASHBOARD, payload: { data } };
  },

  loadDashboardSuccess: data => ({
    type: actions.LOAD_DASHBOARD_SUCCESS,
    payload: { data },
  }),

  loadDashboardError: error => ({
    type: actions.LOAD_DASHBOARD_ERROR,
    payload: { error },
  }),
};
export default actions;
