const actions = {
  LOAD_NOTIFICATIONS: 'LOAD_NOTIFICATIONS',
  LOAD_NOTIFICATIONS_SUCCESS: 'LOAD_NOTIFICATIONS_SUCCESS',
  LOAD_NOTIFICATIONS_ERROR: 'LOAD_NOTIFICATIONS_ERROR',
  SAVE_NOTIFICATION: 'SAVE_NOTIFICATION',
  SAVE_NOTIFICATION_ERROR: 'SAVE_NOTIFICATION_ERROR',
  SAVE_NOTIFICATION_SUCCESS: 'SAVE_NOTIFICATION_SUCCESS',
  TOGGLE_NOTIFICATION_MODAL: 'TOGGLE_NOTIFICATION_MODAL',
  UPDATE_NOTIFICATION: 'UPDATE_NOTIFICATION',

  loadNotifications: (data) => {
    return { type: actions.LOAD_NOTIFICATIONS, payload: { data }, };
  },

  loadNotificationsSuccess: data => ({
    type: actions.LOAD_NOTIFICATIONS_SUCCESS,
    payload: { data },
  }),

  loadNotificationsError: error => ({
    type: actions.LOAD_NOTIFICATIONS_ERROR,
    payload: { error },
  }),

  toggleModal: (data = null) => ({
    type: actions.TOGGLE_NOTIFICATION_MODAL,
    payload: { data },
  }),

  saveNotification: (data, actionName = 'insert') => ({
    type: actions.SAVE_NOTIFICATION,
    payload: { data, actionName },
  }),

  saveNotificationSuccess: () => ({
    type: actions.SAVE_NOTIFICATION_SUCCESS
  }),

  saveNotificationError: error => ({
    type: actions.SAVE_NOTIFICATION_ERROR,
    payload: { error },
  }),

  update: (data) => ({
    type: actions.UPDATE_NOTIFICATION,
    payload: { data },
  })
};
export default actions;
