import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import api from '@iso/lib/services/api'
import _ from 'underscore'
import moment from 'moment'

function* loadDashboard({ payload }) {
  try {
    var data = {}
    if (payload && payload.data) data = payload.data;
    let dashboardData = yield call(api.getDashboardData, data);
    if (dashboardData && dashboardData.data && dashboardData.data.data && dashboardData.data.data.getLoginUserActivities && dashboardData.data.data.getLoginUserActivities.userActivityListData) dashboardData = dashboardData.data.data.getLoginUserActivities
    else dashboardData = []

    if (data.filterBy == 'monthly') {
      const monthList = moment.monthsShort();
      _.map(dashboardData, (dData) => {
        dData._id = monthList[dData._id-1]
        return  dData
      })
    }
    

    let userData = yield call(api.getUsersList, data);
    if (userData && userData.data && userData.data.data && userData.data.data.getUsersList && userData.data.data.getUsersList) {
      dashboardData.users = _.map(userData.data.data.getUsersList, (item) => {
        if (item && item.profile && item.profile.firstName) item.name = item.profile.firstName  +  (item.profile.lastName ? (' ' + item.profile.lastName) : '')
        return item
      })
    }
    else userData.users = []

    yield put(actions.loadDashboardSuccess(dashboardData));
  } catch (error) {
    yield put(actions.loadDashboardError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_DASHBOARD, loadDashboard)
  ]);
}
