import axios from 'axios'
import { getToken } from '@iso/lib/helpers/utility';
// import { createBrowserHistory } from 'history';
// const history = createBrowserHistory({ forceRefresh: true });

const webAPIHost = process.env.REACT_APP_API_HOST

// console.log('webAPIHost', webAPIHost)
function getAPIHeader (token) {
	return {
		headers: {
			'authorization': token
		}
	}
}

// function unauthorizedHandleFn () {
// 	clearToken()
// 	history.push('/signin');
// }

const Api = {
	login: async (data) => {
		data.role = 'admin'
		const query = {
			query: `
				query authUser($email: String, $password: String, $role: String) {
				    authUser(email: $email, password: $password, role: $role) {
				      _id
				      name
				      email
				      role
				      token
				    }
				}
			`,
			variables: data
		}

		return axios.post(webAPIHost, query)
		.then(response => {
			return response
		}).catch((error) => {
			throw error
		})
	},
	// logout: async () => {
	// 	const url = webAPIHost + '/api/logout';
	// 	const token = getToken().get('idToken');
	// 	const options = getAPIHeader(token);
	// 	return axios.get(url, options).then((response) => {
	// 		return response
	// 	}).catch((error) => {
	// 		throw error
	// 	})
	// },
	// Users
	changePassword: (data) => {
		const token = getToken().get('idToken');
		const query = {
			query: `
				mutation changePassword($oldPassword: String, $newPassword: String) {
					changePassword(oldPassword: $oldPassword, newPassword: $newPassword)
				}
			`,
			variables: data
		}
		return axios.post(webAPIHost, query, getAPIHeader(token))
		.then(response => {
			return response
		}).catch((error) => {
			throw error
		})
	},
	getDashboardData: (data) => {
		const token = getToken().get('idToken');
		const query = {
			query: `
				query getLoginUserActivities($filterBy: String, $month: Int, $year: Int) {
					getLoginUserActivities(filterBy: $filterBy, month: $month, year: $year) {
						userActivityListData {
							_id
							users
						}
						lastActivityDate
					}
				}
			`,
			variables: data
		}
		return axios.post(webAPIHost, query, getAPIHeader(token))
		.then(response => {

			return response
		}).catch((error) => {
			throw error
		})
	},
	getUsers: (data) => {
		const token = getToken().get('idToken');
		const query = {
			query: `
				query getUsers ($skip: Int, $limit: Int, $keyword: String) {
					getUsers (skip: $skip, limit: $limit, keyword: $keyword) {
						users {
							_id
					      	email
					      	role
					      	profile {
					      		firstName
					      		lastName
					      		gender
					      		image
					      	}

							approved
							default
						}
						total
					}
				}
			`,
			variables: data
		}
		return axios.post(webAPIHost, query, getAPIHeader(token))
		.then(response => {
			return response
		}).catch((error) => {
			throw error
		})
	},
	getUsersList: (data) => {
		const token = getToken().get('idToken');
		const query = {
			query: `
				query getUsersList {
					getUsersList {
						_id
				      	profile {
				      		firstName
				      		lastName
				      	}
					}
				}
			`,
			variables: data
		}
		return axios.post(webAPIHost, query, getAPIHeader(token))
		.then(response => {
			return response
		}).catch((error) => {
			throw error
		})
	},
	getUserDetails: (data) => {
		const token = getToken().get('idToken');
		const query = {
			query: `
				query getUserDetails ($id: ID) {
					getUserDetails (id: $id) {
						_id
			      email
			      role
			      profile {
			        firstName
			        lastName
			        gender
			        image
			      }
			      approved
					}
				}
			`,
			variables: data
		}
		return axios.post(webAPIHost, query, getAPIHeader(token))
		.then(response => {
			return response
		}).catch((error) => {
			throw error
		})
	},
	saveUser: (data) => {
		data.gender = (data && data.gender) ? data.gender : 'male'
		const token = getToken().get('idToken');
		const query = {
			query: `
				mutation addUser($email: String, $password: String, $firstName: String, $lastName: String, $gender: String, $image: String, $role: String) {
					addUser(email: $email, password: $password, firstName: $firstName, lastName: $lastName, gender: $gender, image: $image, role: $role) {
						_id
				      	email
				      	profile {
				      		firstName
				      		lastName
				      		gender
				      	}
					}
				}
			`,
			variables: data
		}
		return axios.post(webAPIHost, query, getAPIHeader(token))
		.then(response => {
			return response
		}).catch((error) => {
			throw error
		})
	},
	updateUser: (data) => {
		data.userId = data._id
		data.gender = (data && data.gender) ? data.gender : 'male'
		const token = getToken().get('idToken');
		const query = {
			query: `
				mutation updateUser($userId: ID, $email: String, $firstName: String, $lastName: String, $gender: String, $image: String) {
					updateUser(userId: $userId, email: $email, firstName: $firstName, lastName: $lastName, gender: $gender, image: $image) {
						_id
					}
				}
			`,
			variables: data
		}
		return axios.post(webAPIHost, query, getAPIHeader(token))
		.then(response => {
			return response
		}).catch((error) => {
			throw error
		})
	},
	deleteUser: (data) => {
		data.userId = data._id
		const token = getToken().get('idToken');
		const query = {
			query: `
				query deleteUser($userId: ID) {
					deleteUser(userId: $userId)
				}
			`,
			variables: data
		}
		return axios.post(webAPIHost, query, getAPIHeader(token))
		.then(response => {
			return response
		}).catch((error) => {
			throw error
		})
	},
	approveUser: (data) => {
		data.userId = data._id
		data.approved = !data.approved
		const token = getToken().get('idToken');
		const query = {
			query: `
				mutation approveUser($userId: ID, $approved: Boolean) {
					approveUser(userId: $userId, approved: $approved)
				}
			`,
			variables: data
		}
		return axios.post(webAPIHost, query, getAPIHeader(token))
		.then(response => {
			return response
		}).catch((error) => {
			throw error
		})
	},
	getMeasurements: (data) => {
		const token = getToken().get('idToken');
		const query = {
			query: `
				query getMeasurements ($skip: Int, $limit: Int, $keyword: String) {
					getMeasurements (skip: $skip, limit: $limit, keyword: $keyword) {
						measurements {
							_id
							name
							default
						}
						total
					}
				}
			`,
			variables: data
		}
		return axios.post(webAPIHost, query, getAPIHeader(token))
		.then(response => {
			return response
		}).catch((error) => {
			throw error
		})
	},
	getContacts: (data) => {
		const token = getToken().get('idToken');
		const query = {
			query: `
				query getContacts ($skip: Int, $limit: Int, $keyword: String) {
					getContacts (skip: $skip, limit: $limit, keyword: $keyword) {
						contacts {
							_id
							message
							subject
							mobile
							email
							type
						}
						total
					}
				}
			`,
			variables: data
		}
		return axios.post(webAPIHost, query, getAPIHeader(token))
		.then(response => {
			return response
		}).catch((error) => {
			throw error
		})
	},
	deleteContact: (data) => {
		data.contactId = data._id
		const token = getToken().get('idToken');
		const query = {
			query: `
				mutation deleteContact($contactId: ID) {
					deleteContact(contactId: $contactId)
				}
			`,
			variables: data
		}
		return axios.post(webAPIHost, query, getAPIHeader(token))
		.then(response => {
			return response
		}).catch((error) => {
			throw error
		})
	},
	getBanners: (data) => {
		const token = getToken().get('idToken');
		const query = {
			query: `
				query getBanners ($skip: Int, $limit: Int, $keyword: String) {
					getBanners (skip: $skip, limit: $limit, keyword: $keyword) {
						banners {
							_id
							name
							image
							visible
							creatorId {
								_id
								profile {
									firstName
									lastName
								}
							}
						}
						total
					}
				}
			`,
			variables: data
		}
		return axios.post(webAPIHost, query, getAPIHeader(token))
		.then(response => {
			return response
		}).catch((error) => {
			throw error
		})
	},
	saveBanner: (data) => {
		const token = getToken().get('idToken');
		const query = {
			query: `
				mutation addBanner ($name: String, $image: String, $visible: Boolean) {
					addBanner (name: $name, image: $image, visible: $visible) {
						_id
						name
						image
						visible
						creatorId {
							_id
							profile {
								firstName
								lastName
							}
						}
					}
				}
			`,
			variables: data
		}
		return axios.post(webAPIHost, query, getAPIHeader(token))
		.then(response => {
			return response
		}).catch((error) => {
			throw error
		})
	},
	updateBanner: (data) => {
		data.bannerId = data._id;
		const token = getToken().get('idToken');
		const query = {
			query: `
				mutation updateBanner ($bannerId: ID, $name: String, $image: String, $visible: Boolean) {
					updateBanner (bannerId: $bannerId, name: $name, image: $image, visible: $visible) {
						_id
						name
						image
						visible
						creatorId {
							_id
							profile {
								firstName
								lastName
							}
						}
					}
				}
			`,
			variables: data
		}
		return axios.post(webAPIHost, query, getAPIHeader(token))
		.then(response => {
			return response
		}).catch((error) => {
			throw error
		})
	},
	deleteBanner: (data) => {
		data.bannerId = data._id
		const token = getToken().get('idToken');
		const query = {
			query: `
				mutation deleteBanner($bannerId: ID) {
					deleteBanner(bannerId: $bannerId)
				}
			`,
			variables: data
		}
		return axios.post(webAPIHost, query, getAPIHeader(token))
		.then(response => {
			return response
		}).catch((error) => {
			throw error
		})
	},
	getCategories: (data) => {
		const token = getToken().get('idToken');
		const query = {
			query: `
				query getCategories ($skip: Int, $limit: Int, $keyword: String) {
					getCategories (skip: $skip, limit: $limit, keyword: $keyword) {
						categories {
							_id
							name
							photos
							description
							visible
						}
						total
					}
				}
			`,
			variables: data
		}
		return axios.post(webAPIHost, query, getAPIHeader(token))
		.then(response => {
			return response
		}).catch((error) => {
			throw error
		})
	},
	saveCategory: (data) => {
		const token = getToken().get('idToken');
		const query = {
			query: `
				mutation addCategory ($name: String, $description: String, $visible: Boolean, $photos: [String]) {
					addCategory (name: $name, description: $description, visible: $visible, photos: $photos) {
						_id
						name
						photos
						description
						visible
					}
				}
			`,
			variables: data
		}
		return axios.post(webAPIHost, query, getAPIHeader(token))
		.then(response => {
			return response
		}).catch((error) => {
			throw error
		})
	},
	updateCategory: (data) => {
		data.categoryId = data._id;
		const token = getToken().get('idToken');
		const query = {
			query: `
				mutation updateCategory ($categoryId: ID, $name: String, $description: String, $visible: Boolean, $photos: [String]) {
					updateCategory (categoryId: $categoryId, name: $name, description: $description, visible: $visible, photos: $photos) {
						_id
						name
						photos
						description
						visible
					}
				}
			`,
			variables: data
		}
		return axios.post(webAPIHost, query, getAPIHeader(token))
		.then(response => {
			return response
		}).catch((error) => {
			throw error
		})
	},
	deleteCategory: (data) => {
		data.categoryId = data._id
		const token = getToken().get('idToken');
		const query = {
			query: `
				mutation deleteCategory($categoryId: ID) {
					deleteCategory(categoryId: $categoryId)
				}
			`,
			variables: data
		}
		return axios.post(webAPIHost, query, getAPIHeader(token))
		.then(response => {
			return response
		}).catch((error) => {
			throw error
		})
	},
	getProducts: (data) => {
		const token = getToken().get('idToken');
		const query = {
			query: `
				query getProducts ($skip: Int, $limit: Int, $keyword: String) {
					getProducts (skip: $skip, limit: $limit, keyword: $keyword) {
						products {
							_id
							name
							categories
							shortDescription
							description
							price
							specialPrice
							photos
							visible
							showInHomePage
						}
						total
					}
				}
			`,
			variables: data
		}
		return axios.post(webAPIHost, query, getAPIHeader(token))
		.then(response => {
			return response
		}).catch((error) => {
			throw error
		})
	},
	saveProduct: (data) => {
		const token = getToken().get('idToken');
		data.price = data && data.price ? parseInt(data.price) : 0
		const query = {
			query: `
				mutation addProduct ($name: String, $categories: [ID], $shortDescription: String, $description: String, $price: Int, $specialPrice: Int, $visible: Boolean, $photos: [String], $showInHomePage: Boolean) {
					addProduct (name: $name, categories: $categories, shortDescription: $shortDescription, description: $description, price: $price, specialPrice: $specialPrice, visible: $visible, photos: $photos, showInHomePage: $showInHomePage) {
						_id
						name
						categories
						shortDescription
						description
						price
						specialPrice
						photos
						visible
						showInHomePage
					}
				}
			`,
			variables: data
		}
		return axios.post(webAPIHost, query, getAPIHeader(token))
		.then(response => {
			return response
		}).catch((error) => {
			throw error
		})
	},
	updateProduct: (data) => {
		data.productId = data._id;
		data.price = data && data.price ? parseInt(data.price) : 0
		const token = getToken().get('idToken');
		const query = {
			query: `
				mutation updateProduct ($productId: ID, $name: String, $categories: [ID], $shortDescription: String, $description: String, $price: Int, $specialPrice: Int, $visible: Boolean, $photos: [String], $showInHomePage: Boolean) {
					updateProduct (productId: $productId, name: $name, categories: $categories, shortDescription: $shortDescription, description: $description, price: $price, specialPrice: $specialPrice, visible: $visible, photos: $photos, showInHomePage: $showInHomePage) {
						_id
						name
						categories
						shortDescription
						description
						price
						specialPrice
						photos
						visible
						showInHomePage
					}
				}
			`,
			variables: data
		}
		return axios.post(webAPIHost, query, getAPIHeader(token))
		.then(response => {
			return response
		}).catch((error) => {
			throw error
		})
	},
	deleteProduct: (data) => {
		data.productId = data._id
		const token = getToken().get('idToken');
		const query = {
			query: `
				mutation deleteProduct($productId: ID) {
					deleteProduct(productId: $productId)
				}
			`,
			variables: data
		}
		return axios.post(webAPIHost, query, getAPIHeader(token))
		.then(response => {
			return response
		}).catch((error) => {
			throw error
		})
	},
	getNotifications: (data) => {
		const token = getToken().get('idToken');
		const query = {
			query: `
				query getNotifications ($skip: Int, $limit: Int, $keyword: String) {
					getNotifications (skip: $skip, limit: $limit, keyword: $keyword) {
						notifications {
							_id
							message
							type
						}
						total
					}
				}
			`,
			variables: data
		}
		return axios.post(webAPIHost, query, getAPIHeader(token))
		.then(response => {
			return response
		}).catch((error) => {
			throw error
		})
	},
	saveNotification: (data) => {
		data.price = parseInt(data.price);
		const token = getToken().get('idToken');
		const query = {
			query: `
				mutation addNotification ($message: String) {
					addNotification (message: $message) {
						_id
						message
						type
					}
				}
			`,
			variables: data
		}
		return axios.post(webAPIHost, query, getAPIHeader(token))
		.then(response => {
			return response
		}).catch((error) => {
			throw error
		})
	},
	deleteNotification: (data) => {
		data.notificationId = data._id
		const token = getToken().get('idToken');
		const query = {
			query: `
				mutation deleteNotification($notificationId: ID) {
					deleteNotification(notificationId: $notificationId)
				}
			`,
			variables: data
		}
		return axios.post(webAPIHost, query, getAPIHeader(token))
		.then(response => {
			return response
		}).catch((error) => {
			throw error
		})
	},
	getUserMeasurements: (data) => {
		const token = getToken().get('idToken');
		// data.userId = data.id;
		const query = {
			query: `
				query getUserMeasurements ($skip: Int, $limit: Int, $keyword: String, $userId: ID) {
					getUserMeasurements (skip: $skip, limit: $limit, keyword: $keyword, userId: $userId) {
						userMeasurements {
							_id
							name
					      	addedAt
					      	message
							measurements {
								measurementId {
									_id
									name
									default
								}
								value
							}
						}
						total
					}
				}
			`,
			variables: data
		}
		return axios.post(webAPIHost, query, getAPIHeader(token))
		.then(response => {
			return response
		}).catch((error) => {
			throw error
		})
	},
	saveUserMeasurement: (data) => {
		const token = getToken().get('idToken');
		const query = {
			query: `
				mutation addUserMeasurement ($addedAt: Date, $message: String, $measurements: [measurementInput], $userId: ID) {
					addUserMeasurement (addedAt: $addedAt, message: $message, measurements: $measurements, userId: $userId) {
						_id
					}
				}
			`,
			variables: data.measurementData
		}
		return axios.post(webAPIHost, query, getAPIHeader(token))
		.then(response => {
			return response
		}).catch((error) => {
			throw error
		})
	},
	getSettingDetails: (data) => {
		const query = {
			query: `
				query getSettingDetails {
					getSettingDetails {
						name
						email
						address {
							address1
							address2
							city
							country
							zipCode
						}
						primaryLogo
						secondaryLogo
						secondarySmallLogo
						colors {
							primary
							secondary
							tertiary
						}
						privacy
						terms
						mobile
						about {
							text
							mission
							vision
						}
					}
				}
			`,
			variables: data
		}
		return axios.post(webAPIHost, query)
		.then(response => {
			return response
		}).catch((error) => {
			throw error
		})
	},
	saveSetting: (data) => {
		const token = getToken().get('idToken');

		data.address = {
			address1: data.address1,
			address2: data.address2,
			city: data.city,
			country: data.country,
			zipCode: data.zipCode
		}
		data.colors = {
			primary: data.primary,
			secondary: data.secondary,
			tertiary: data.tertiary
		}
		delete data.address1;
		delete data.address2;
		delete data.city;
		delete data.country;
		delete data.zipCode;
		delete data.primary;
		delete data.secondary;
		delete data.tertiary;
		const query = {
			query: `
				mutation updateSetting ($name: String, $email: String, $address: addressInput, $primaryLogo: String, $secondaryLogo: String, $secondarySmallLogo: String, $colors: colorInput, $privacy: String, $terms: String, $mobile: String, $about: aboutInput) {
					updateSetting (name: $name, email: $email, address: $address, primaryLogo: $primaryLogo, secondaryLogo: $secondaryLogo, secondarySmallLogo: $secondarySmallLogo, colors: $colors, privacy: $privacy, terms: $terms, mobile: $mobile, about: $about)
				}
			`,
			variables: data
		}
		return axios.post(webAPIHost, query, getAPIHeader(token))
		.then(response => {
			return response
		}).catch((error) => {
			throw error
		})
	}
}

export default Api;
