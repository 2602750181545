import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import api from '@iso/lib/services/api'
import toastr from 'toastr'

function* loadSettings({ payload }) {
  try {
    var data = {}
    if (payload && payload.data) data = payload.data;

    let settingData = yield call(api.getSettingDetails, data);
    if (settingData && settingData.data && settingData.data.data && settingData.data.data.getSettingDetails) {
      settingData = settingData.data.data.getSettingDetails
      settingData.address1 = settingData && settingData.address && settingData.address.address1 ? settingData.address.address1 : ''
      settingData.address2 = settingData && settingData.address && settingData.address.address2 ? settingData.address.address2 : ''
      settingData.city = settingData && settingData.address && settingData.address.city ? settingData.address.city : ''
      settingData.country = settingData && settingData.address && settingData.address.country ? settingData.address.country : ''
      settingData.zipCode = settingData && settingData.address && settingData.address.zipCode ? settingData.address.zipCode : ''
      settingData.primary = settingData && settingData.colors && settingData.colors.primary ? settingData.colors.primary : '#f8cf2c'
      settingData.secondary = settingData && settingData.colors && settingData.colors.secondary ? settingData.colors.secondary : '#939393'
      settingData.tertiary = settingData && settingData.colors && settingData.colors.tertiary ? settingData.colors.tertiary : '#363636'
      settingData.privacy = settingData && settingData.privacy ? settingData.privacy : ''
      settingData.terms = settingData && settingData.terms ? settingData.terms : ''
      settingData.mobile = settingData && settingData.mobile ? settingData.mobile : ''
      settingData.about = settingData && settingData.about ? settingData.about : { text: '', mission: '', vision: '' }
      delete settingData.address
      delete settingData.colors
    } else settingData = {}
    yield put(actions.loadSettingsSuccess(settingData));
  } catch (error) {
    yield put(actions.loadSettingsError(error));
  }
}

function* saveSetting({ payload }) {
  const { data, actionName } = payload;
  try {
    yield call(api.saveSetting, data);
    toastr.success('Setting saved successfully')

    yield put(actions.saveSettingSuccess());
    yield put({ type: actions.LOAD_SETTINGS, payload: { data: {} } });
  } catch (error) {
    toastr.error((error && error.response && error.response.data && error.response.data.error) ? error.response.data.error : ('Error in setting ' + actionName))
    yield put(actions.saveSettingError(error));
  }
}


export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_SETTINGS, loadSettings),
    takeEvery(actions.SAVE_SETTING, saveSetting)
  ]);
}
