const actions = {
  LOAD_SETTINGS: 'LOAD_SETTINGS',
  LOAD_SETTINGS_SUCCESS: 'LOAD_SETTINGS_SUCCESS',
  LOAD_SETTINGS_ERROR: 'LOAD_SETTINGS_ERROR',
  SAVE_SETTING: 'SAVE_SETTING',
  SAVE_SETTING_ERROR: 'SAVE_SETTING_ERROR',
  SAVE_SETTING_SUCCESS: 'SAVE_SETTING_SUCCESS',
  TOGGLE_SETTING_MODAL: 'TOGGLE_SETTING_MODAL',
  UPDATE_SESSION: 'UPDATE_SESSION',
  UPDATE_SETTING: 'UPDATE_SETTING',

  loadSettings: (data) => {
    return { type: actions.LOAD_SETTINGS, payload: { data }, };
  },

  loadSettingsSuccess: data => ({
    type: actions.LOAD_SETTINGS_SUCCESS,
    payload: { data },
  }),

  loadSettingsError: error => ({
    type: actions.LOAD_SETTINGS_ERROR,
    payload: { error },
  }),

  toggleModal: (data = null) => ({
    type: actions.TOGGLE_SETTING_MODAL,
    payload: { data },
  }),

  saveSetting: (data, actionName = 'insert') => ({
    type: actions.SAVE_SETTING,
    payload: { data, actionName },
  }),

  saveSettingSuccess: () => ({
    type: actions.SAVE_SETTING_SUCCESS
  }),

  saveSettingError: error => ({
    type: actions.SAVE_SETTING_ERROR,
    payload: { error },
  }),

  update: (data) => ({
    type: actions.UPDATE_SETTING,
    payload: { data },
  })
};
export default actions;
