import actions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  products: [],
  total: 0,
  modalActive: false,
  product: {visible: true},
};

export default function reducer(
  state = initState,
  { type, payload }
) {
  switch (type) {
    case actions.LOAD_PRODUCTS:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
    case actions.LOAD_PRODUCTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        products: payload.data.products ? payload.data.products : [],
        total: payload.data.total ? payload.data.total : 0,
        errorMessage: false,
      };
    case actions.LOAD_PRODUCTS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'There is a loading problem',
      };
    case actions.TOGGLE_PRODUCT_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        product: payload.data == null ? initState.product : payload.data,
      };
    case actions.UPDATE_PRODUCT:
      return {
        ...state,
        product: payload.data,
      };
    case actions.SAVE_PRODUCT:
      return {
        ...state,
        isLoading: true
      };
    case actions.SAVE_PRODUCT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: false,
      };
    case actions.SAVE_PRODUCT_ERROR:
      return {
        ...state,
        errorMessage: 'Error in save product',
        isLoading: false
      };
    default:
      return state;
  }
}
