const actions = {
  LOAD_PRODUCTS: 'LOAD_PRODUCTS',
  LOAD_PRODUCTS_SUCCESS: 'LOAD_PRODUCTS_SUCCESS',
  LOAD_PRODUCTS_ERROR: 'LOAD_PRODUCTS_ERROR',
  SAVE_PRODUCT: 'SAVE_PRODUCT',
  SAVE_PRODUCT_ERROR: 'SAVE_PRODUCT_ERROR',
  SAVE_PRODUCT_SUCCESS: 'SAVE_PRODUCT_SUCCESS',
  TOGGLE_PRODUCT_MODAL: 'TOGGLE_PRODUCT_MODAL',
  UPDATE_PRODUCT: 'UPDATE_PRODUCT',

  loadProducts: (data) => {
    return { type: actions.LOAD_PRODUCTS, payload: { data }, };
  },

  loadProductsSuccess: data => ({
    type: actions.LOAD_PRODUCTS_SUCCESS,
    payload: { data },
  }),

  loadProductsError: error => ({
    type: actions.LOAD_PRODUCTS_ERROR,
    payload: { error },
  }),

  toggleModal: (data = null) => ({
    type: actions.TOGGLE_PRODUCT_MODAL,
    payload: { data },
  }),

  saveProduct: (data, actionName = 'insert') => ({
    type: actions.SAVE_PRODUCT,
    payload: { data, actionName },
  }),

  saveProductSuccess: () => ({
    type: actions.SAVE_PRODUCT_SUCCESS
  }),

  saveProductError: error => ({
    type: actions.SAVE_PRODUCT_ERROR,
    payload: { error },
  }),

  update: (data) => ({
    type: actions.UPDATE_PRODUCT,
    payload: { data },
  })
};
export default actions;
