import actions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  contacts: [],
  contact: {}
};

export default function reducer(
  state = initState,
  { type, payload }
) {
  switch (type) {
    case actions.LOAD_CONTACTS:
      return {
        ...state,
        isLoading: true,
        errorMessage: false
      };
    case actions.LOAD_CONTACTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        contacts: payload.data.contacts ? payload.data.contacts : [],
        total: payload.data.total ? payload.data.total : 0,
        errorMessage: false,
      };
    case actions.LOAD_CONTACTS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'There is a loading problem',
      };
    default:
      return state;
  }
}
