import AWS from 'aws-sdk';
import { getFileName } from '@iso/lib/helpers/utility';
//
AWS.config.update({ accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY, secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY });
AWS.config.region = process.env.REACT_APP_AWS_REGION;
var s3Obj = new AWS.S3({ params: { Bucket: process.env.REACT_APP_AWS_BUCKET } });

const Core = {
	fileUpload: (file, key, cb) => {
		// console.log('file api', file)
		//
		if(file) {
			// Update file name
			const fileName = getFileName(file, key)
			//
			let fileData = {}
			//
			var params = { 'ACL': 'private', Key: fileName, ContentType: file.type, Body: file, ServerSideEncryption: 'AES256' };
			s3Obj.upload(params, function(err, data) {
				if(err) {
					// There Was An Error With Your S3 Config
					// console.log(err, 'ERROR FILE')
					// throw err
					cb(err, null)
				} else {
					cb(null, data.Location)
				}
			})
			return fileData;
		}
		else {
			return 'No File Selected'
		}

	},
	getSignedUrl: (url) => {
		let key = ''
		if (url.indexOf('amazonaws.com/') === -1) key = url
		else {
			const urlArray = url.split('amazonaws.com/')
			if (urlArray && urlArray.length) key = urlArray[1]
		}
		return s3Obj.getSignedUrl('getObject', { Bucket: process.env.REACT_APP_AWS_BUCKET, Key: key })
	}

}

export default Core;
