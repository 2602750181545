import actions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  categories: [],
  total: 0,
  modalActive: false,
  category: {visible: true},
};

export default function reducer(
  state = initState,
  { type, payload }
) {
  switch (type) {
    case actions.LOAD_CATEGORIES:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
    case actions.LOAD_CATEGORIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        categories: payload.data.categories ? payload.data.categories : [],
        total: payload.data.total ? payload.data.total : 0,
        errorMessage: false,
      };
    case actions.LOAD_CATEGORIES_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'There is a loading problem',
      };
    case actions.TOGGLE_CATEGORY_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        category: payload.data == null ? initState.category : payload.data,
      };
    case actions.UPDATE_CATEGORY:
      return {
        ...state,
        category: payload.data,
      };
    case actions.SAVE_CATEGORY:
      return {
        ...state,
        isLoading: true
      };
    case actions.SAVE_CATEGORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: false,
      };
    case actions.SAVE_CATEGORY_ERROR:
      return {
        ...state,
        errorMessage: 'Error in save category',
        isLoading: false
      };
    default:
      return state;
  }
}
