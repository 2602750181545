import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import api from '@iso/lib/services/api'
import toastr from 'toastr'

function* loadProducts({ payload }) {
  try {
    var data = {}
    if (payload && payload.data) data = payload.data;

    let productData = yield call(api.getProducts, data);
    if (productData && productData.data && productData.data.data && productData.data.data.getProducts && productData.data.data.getProducts.products) productData = productData.data.data.getProducts
    else productData = []
    yield put(actions.loadProductsSuccess(productData));
  } catch (error) {
    yield put(actions.loadProductsError(error));
  }
}

function* saveProduct({ payload }) {
  const { data, actionName } = payload;
  try {
    switch (actionName) {
      case 'delete':
        yield call(api.deleteProduct, data);
        toastr.success('Product deleted successfully')
        break;
      case 'update':
        yield call(api.updateProduct, data);
        toastr.success('Product updated successfully')
        break;
      default:
        yield call(api.saveProduct, data);
        toastr.success('Product saved successfully')
        break;
    }
    yield put(actions.saveProductSuccess());
    yield put({ type: actions.LOAD_PRODUCTS, payload: { data: { skip: 0, limit: 15 } } });
  } catch (error) {
    toastr.error((error && error.response && error.response.data && error.response.data.error) ? error.response.data.error : ('Error in product ' + actionName))
    yield put(actions.saveProductError(error));
  }
}


export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_PRODUCTS, loadProducts),
    takeEvery(actions.SAVE_PRODUCT, saveProduct)
  ]);
}
