import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import api from '@iso/lib/services/api'
import toastr from 'toastr'

function* loadBanners({ payload }) {
  try {
    var data = {}
    if (payload && payload.data) data = payload.data;

    let bannerData = yield call(api.getBanners, data);
    if (bannerData && bannerData.data && bannerData.data.data && bannerData.data.data.getBanners && bannerData.data.data.getBanners.banners) bannerData = bannerData.data.data.getBanners
    else bannerData = []
    yield put(actions.loadBannersSuccess(bannerData));
  } catch (error) {
    yield put(actions.loadBannersError(error));
  }
}

function* saveBanner({ payload }) {
  const { data, actionName } = payload;
  try {
    switch (actionName) {
      case 'delete':
        yield call(api.deleteBanner, data);
        toastr.success('Banner deleted successfully')
        break;
      case 'update':
        yield call(api.updateBanner, data);
        toastr.success('Banner updated successfully')
        break;
      default:
        yield call(api.saveBanner, data);
        toastr.success('Banner saved successfully')
        break;
    }
    yield put(actions.saveBannerSuccess());
    yield put({ type: actions.LOAD_BANNERS, payload: { data: { skip: 0, limit: 15 } } });
  } catch (error) {
    toastr.error((error && error.response && error.response.data && error.response.data.error) ? error.response.data.error : ('Error in banner ' + actionName))
    yield put(actions.saveBannerError(error));
  }
}


export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_BANNERS, loadBanners),
    takeEvery(actions.SAVE_BANNER, saveBanner)
  ]);
}
