import actions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  chartData: [],
  users: [],
  exercises: [],
  modalActive: false,
  exercisesStatistics: [],
  lastActivityDate: '',
  lastStatisticsDate: ''
};

export default function reducer(
  state = initState,
  { type, payload }
) {
  switch (type) {
    case actions.LOAD_DASHBOARD:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
    case actions.LOAD_DASHBOARD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        chartData: payload.data && payload.data.userActivityListData ? payload.data.userActivityListData : [],
        users: payload.data && payload.data.users ? payload.data.users : [],
        lastActivityDate: payload.data && payload.data.lastActivityDate ? payload.data.lastActivityDate : '',
        // exercises: payload.data.exercises ? payload.data.exercises : [],
        errorMessage: false,
      };
    case actions.LOAD_DASHBOARD_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'There is a loading problem',
      };
    case actions.GET_EXERCISES_STATISTICS:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
    case actions.GET_EXERCISES_STATISTICS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        exercisesStatistics: payload.data && payload.data.exerciseStatistics ? payload.data.exerciseStatistics : [],
        lastStatisticsDate: payload.data && payload.data.lastStatisticsDate ? payload.data.lastStatisticsDate : '',
        errorMessage: false,
      };
    case actions.GET_EXERCISES_STATISTICS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'There is a loading problem',
      };
    case actions.GET_EXERCISES_BY_USERID:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
    case actions.GET_EXERCISES_BY_USERID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        exercises: payload.data ? payload.data : [],
        errorMessage: false,
      };
    case actions.GET_EXERCISES_BY_USERID_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'There is a loading problem',
      };
    default:
      return state;
  }
}
