import toastr from 'toastr'
import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';
import api from '@iso/lib/services/api'

import { getToken, clearToken } from '@iso/lib/helpers/utility';
import actions from './actions';

const history = createBrowserHistory();
// const fakeApiCall = false; // auth0 or express JWT

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function*({ payload }) {
    const { data, token } = payload;
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token: token,
        profile: 'Profile',
      });
    } else {
      if (data.email && data.password) {
        try {
          let result = yield call(api.login, data)
          if (result && result.data && result.data.data && result.data.data.authUser && result.data.data.authUser.token) {
            result.data = result.data.data.authUser
            yield put({
              type: actions.LOGIN_SUCCESS,
              token: result.data.token,
              profile: 'Profile UserId' + result.data.userId,
            });
            toastr.success('Login successful')
          } else {
            toastr.error('User not found, or invalid password')
            yield put({ type: actions.LOGIN_ERROR });
          }
        } catch (e) {
          toastr.error('User not found, or invalid password')
          yield put({ type: actions.LOGIN_ERROR });
        }
      } else {
        toastr.error('Invalid input')
        yield put({ type: actions.LOGIN_ERROR });
      }
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function*(payload) {
    yield localStorage.setItem('id_token', payload.token);
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function*() {});
}

// export function* logout() {
//   yield takeEvery(actions.LOGOUT, function*() {
//     yield clearToken();
//     history.push('/');
//   });
// }
export function* logout() {
  yield takeEvery(actions.LOGOUT, function*() {
    try {
      yield call(api.logout);
    } catch (e) {
      console.log(e);
    }
    yield clearToken();
    history.push('/signin');
  });
}

export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
    const token = getToken().get('idToken');
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        profile: 'Profile',
      });
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
  ]);
}
