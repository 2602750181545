import actions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  total: 0,
  modalActive: false,
  setting: {primary: '#f8cf2c', secondary: '#939393', tertiary: '#363636'},
};

export default function reducer(
  state = initState,
  { type, payload }
) {
  switch (type) {
    case actions.LOAD_SETTINGS:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
    case actions.LOAD_SETTINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        setting: payload.data ? payload.data : {},
        errorMessage: false,
      };
    case actions.LOAD_SETTINGS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'There is a loading problem',
      };
    case actions.TOGGLE_SETTING_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        setting: payload.data == null ? initState.setting : payload.data,
      };
    case actions.UPDATE_SETTING:
      return {
        ...state,
        setting: payload.data,
      };
    case actions.SAVE_SETTING:
      return {
        ...state,
        isLoading: true
      };
    case actions.SAVE_SETTING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: false,
      };
    case actions.SAVE_SETTING_ERROR:
      return {
        ...state,
        errorMessage: 'Error in save setting',
        isLoading: false
      };
    default:
      return state;
  }
}
