import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import api from '@iso/lib/services/api'
import toastr from 'toastr'

function* loadCategories({ payload }) {
  try {
    var data = {}
    if (payload && payload.data) data = payload.data;

    let categoryData = yield call(api.getCategories, data);
    if (categoryData && categoryData.data && categoryData.data.data && categoryData.data.data.getCategories && categoryData.data.data.getCategories.categories) categoryData = categoryData.data.data.getCategories
    else categoryData = []
    yield put(actions.loadCategoriesSuccess(categoryData));
  } catch (error) {
    yield put(actions.loadCategoriesError(error));
  }
}

function* saveCategory({ payload }) {
  const { data, actionName } = payload;
  try {
    switch (actionName) {
      case 'delete':
        yield call(api.deleteCategory, data);
        toastr.success('Category deleted successfully')
        break;
      case 'update':
        yield call(api.updateCategory, data);
        toastr.success('Category updated successfully')
        break;
      default:
        yield call(api.saveCategory, data);
        toastr.success('Category saved successfully')
        break;
    }
    yield put(actions.saveCategorySuccess());
    yield put({ type: actions.LOAD_CATEGORIES, payload: { data: { skip: 0, limit: 15 } } });
  } catch (error) {
    toastr.error((error && error.response && error.response.data && error.response.data.error) ? error.response.data.error : ('Error in category ' + actionName))
    yield put(actions.saveCategoryError(error));
  }
}


export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_CATEGORIES, loadCategories),
    takeEvery(actions.SAVE_CATEGORY, saveCategory)
  ]);
}
