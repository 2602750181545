import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import api from '@iso/lib/services/api'
import toastr from 'toastr'

function* loadContacts({ payload }) {
  try {
    var data = {}
    if (payload && payload.data) data = payload.data;

    let contactData = yield call(api.getContacts, data);
    if (contactData && contactData.data && contactData.data.data && contactData.data.data.getContacts && contactData.data.data.getContacts.contacts) contactData = contactData.data.data.getContacts
    else contactData = []

    yield put(actions.loadContactsSuccess(contactData));
  } catch (error) {
    yield put(actions.loadContactsError(error));
  }
}

function* saveContact({ payload }) {
  const { data, actionName } = payload;
  try {
    switch (actionName) {
      case 'delete':
        yield call(api.deleteContact, data);
        toastr.success('Contact deleted successfully')
        break;
    }
    yield put(actions.saveContactSuccess());
    yield put({ type: actions.LOAD_CONTACTS, payload: { data: { skip: 0, limit: 15 } } });
  } catch (error) {
    toastr.error((error && error.response && error.response.data && error.response.data.error) ? error.response.data.error : ('Error in contact ' + actionName))
    yield put(actions.saveContactError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_CONTACTS, loadContacts),
    takeEvery(actions.SAVE_CONTACT, saveContact)
  ]);
}
