import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import api from '@iso/lib/services/api'
import toastr from 'toastr'
import { createBrowserHistory } from 'history';
import _ from 'underscore'

const history = createBrowserHistory();

function* loadUsers({ payload }) {
  try {
    var data = {}
    if (payload && payload.data) data = payload.data;

    let userData = yield call(api.getUsers, data);
    if (userData && userData.data && userData.data.data && userData.data.data.getUsers && userData.data.data.getUsers.users) userData = userData.data.data.getUsers
    else userData = []

    yield put(actions.loadUsersSuccess(userData));
  } catch (error) {
    yield put(actions.loadUsersError(error));
  }
}

function* userDetails({ payload }) {
  try {
    var data = {}
    if (payload && payload.data) data = payload.data;

    let userData = yield call(api.getUserDetails, data);
    // console.log(userData, 'DATA')
    if (userData && userData.data && userData.data.data) {
      yield put({ type: actions.USER_DETAIL_SUCCESS, payload: { data: userData.data.data.getUserDetails } })
    }
  } catch (error) {
    yield put({ type: actions.USER_DETAIL_ERROR });
  }
}

function* saveUser({ payload }) {
  const { data, actionName } = payload;
  // console.log('actionName, data', actionName, data)
  try {
    let errorStatus = false
    switch (actionName) {
      case 'delete':
        yield call(api.deleteUser, data);
        toastr.success(`${data && data.role && data.role === 'admin' ? 'Admin' : 'User'} deleted successfully`)
        break;
      case 'update':
        yield call(api.updateUser, data);
        toastr.success(`${data && data.role && data.role === 'admin' ? 'Admin' : 'User'} updated successfully`)
        break;
      default: {
        const result = yield call(api.saveUser, data);
        if (result && result.data && result.data.data.addUser) {
          toastr.success(`${data && data.role && data.role === 'admin' ? 'Admin' : 'User'} saved successfully`)
        } else {
          errorStatus = true
          const error = result && result.data && result.data.errors ? result.data.errors[0] : {}
          toastr.error(error.message ? error.message : 'Error in user creation')
          yield put(actions.saveUserError(error))
        }
        break;
      }
    }
    if (!errorStatus) {
      yield put(actions.saveUserSuccess());
      yield put({ type: actions.LOAD_USERS, payload: { data: { skip: 0, limit: 15 } } });
    }
  } catch (error) {
    toastr.error((error && error.response && error.response.data && error.response.data.error) ? error.response.data.error : ('Error in user ' + actionName))
    yield put(actions.saveUserError(error));
  }
}

function* saveUserMeasurement({ payload }) {
  const { data } = payload;
  // console.log('actionName, data', actionName, data)
  try {
    let errorStatus = false
    const result = yield call(api.saveUserMeasurement, data);
    if (result && result.data && result.data.data.addUserMeasurement) {
      toastr.success('User measurement saved successfully')
    } else {
      errorStatus = true
      const error = result && result.data && result.data.errors ? result.data.errors[0] : {}
      toastr.error(error.message ? error.message : 'Error in user measurement creation')
      yield put(actions.saveUserMeasurementError(error))
    }

    if (!errorStatus) {
      yield put(actions.saveUserMeasurementSuccess());
      yield put({ type: actions.LOAD_USER_MEASUREMENTS, payload: { data: { userId: (data && data.measurementData && data.measurementData.userId) ?  data.measurementData.userId : ''} } });
      yield put({ type: actions.USER_DETAIL, payload: { data: { id: (data && data.measurementData && data.measurementData.userId) ?  data.measurementData.userId : ''} } });
    }
  } catch (error) {
    toastr.error((error && error.response && error.response.data && error.response.data.error) ? error.response.data.error : ('Error in user measurement creation'))
    yield put(actions.saveUserMeasurementError(error));
  }
}

function* approveUser({ payload }) {
  const { data } = payload;
  try {
    yield call(api.approveUser, data);
    toastr.success('User approved successfully')

    yield put(actions.approveUserSuccess());
    yield put({ type: actions.LOAD_USERS, payload: { data: { skip: 0, limit: 15 } } });
  } catch (error) {
    toastr.error((error && error.response && error.response.data && error.response.data.error) ? error.response.data.error : ('Error in approve user'))
    yield put(actions.approveUserError(error));
  }
}

function* changePassword({ payload }) {
  const { data } = payload;
  try {
    const result = yield call(api.changePassword, data);
    if (result && result.data && result.data.data.changePassword) {
      toastr.success('Password updated successfully')
      history.push('/dashboard/user');
      yield put(actions.changePasswordSuccess());
      setTimeout(function () {
        window.location.reload()
      }, 1000)
    } else {
      const error = result && result.data && result.data.errors ? result.data.errors[0] : {}
      toastr.error('Incorrect current password')
      yield put(actions.changePasswordError(error.message))
    }

  } catch (error) {
    toastr.error((error && error.response && error.response.data && error.response.data.error) ? error.response.data.error : ('Error in change password'))
    yield put(actions.changePasswordError(error));
  }
}

function* loadUserMeasurements({ payload }) {
  try {
    var data = {}
    if (payload && payload.data) data = payload.data;

    let userMeasureData = yield call(api.getUserMeasurements, data);
    if (userMeasureData && userMeasureData.data && userMeasureData.data.data && userMeasureData.data.data.getUserMeasurements && userMeasureData.data.data.getUserMeasurements.userMeasurements) userMeasureData = userMeasureData.data.data.getUserMeasurements
    else userMeasureData = []

    let measurementData = yield call(api.getMeasurements, data);
    if (measurementData && measurementData.data && measurementData.data.data && measurementData.data.data.getMeasurements) {

      userMeasureData.measurements = _.map(measurementData.data.data.getMeasurements.measurements, measurement => {
        return { measurementId: measurement._id, name: measurement.name, value: measurement.value || '', default: measurement.default || 0 }
      })
    } else userMeasureData.measurements = []

    yield put(actions.loadMeasurementsSuccess(userMeasureData));
  } catch (error) {
    yield put(actions.loadMeasurementsError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_USERS, loadUsers),
    takeEvery(actions.USER_DETAIL, userDetails),
    takeEvery(actions.SAVE_USER, saveUser),
    takeEvery(actions.APPROVE_USER, approveUser),
    takeEvery(actions.CHANGE_PASSWORD, changePassword),
    takeEvery(actions.LOAD_USER_MEASUREMENTS, loadUserMeasurements),
    takeEvery(actions.SAVE_USER_MEASUREMENT, saveUserMeasurement)
  ]);
}
