const actions = {
  LOAD_CONTACTS: 'LOAD_CONTACTS',
  LOAD_CONTACTS_SUCCESS: 'LOAD_CONTACTS_SUCCESS',
  LOAD_CONTACTS_ERROR: 'LOAD_CONTACTS_ERROR',
  SAVE_CONTACT: 'SAVE_CONTACT',
  SAVE_CONTACT_SUCCESS: 'SAVE_CONTACT_SUCCESS',
  SAVE_CONTACT_ERROR: 'SAVE_CONTACT_ERROR',

  loadContacts: (data) => {
    return { type: actions.LOAD_CONTACTS, payload: { data }, };
  },

  loadContactsSuccess: data => ({
    type: actions.LOAD_CONTACTS_SUCCESS,
    payload: { data },
  }),

  loadContactsError: error => ({
    type: actions.LOAD_CONTACTS_ERROR,
    payload: { error },
  }),

  saveContact: (data, actionName = 'insert') => ({
    type: actions.SAVE_CONTACT,
    payload: { data, actionName },
  }),

  saveContactSuccess: () => ({
    type: actions.SAVE_CONTACT_SUCCESS
  }),

  saveContactError: error => ({
    type: actions.SAVE_CONTACT_ERROR,
    payload: { error },
  }),
};
export default actions;
