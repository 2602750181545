export const PUBLIC_ROUTE = {
	LANDING: '/',
	SIGN_IN: '/signin',
	SIGN_UP: '/signup',
	FORGET_PASSWORD: '/forgotpassword',
	RESET_PASSWORD: '/resetpassword',
	PAGE_404: '/404',
	PAGE_500: '/500',
	AUTH0_CALLBACK: '/auth0loginCallback',
};

export const PRIVATE_ROUTE = {
	DASHBOARD: '/',
	USER: '/user',
	USER_DETAIL: '/user/:id',
	CONTACT: '/contact',
	BANNER: '/banner',
	CATEGORY: '/category',
	PRODUCT: '/product',
	ACTIVITY: '/activity',
	MEASUREMENT: '/measurement',
	MUSCLE_GROUP: '/muscle-group',
	SUBSCRIPTION: '/subscription',
	SESSION: '/session',
	NOTIFICATION: '/notification',
	QUESTION: '/question',
	EXERCISE: '/exercise',
	WORKOUT: '/workout',
	CHANGE_PASSWORD: '/change-password',
	LEADER_BOARD: '/leader-board',
	SETTING: '/setting'
};
