import React from 'react';
import ReactDOM from 'react-dom';

import App from './app';
import * as serviceWorker from './serviceWorker';

import editor from './assets/plugins/medium-editor-insert-plugin.js'
import './assets/js/custom.js'

import 'toastr/build/toastr.min.css'
import 'medium-editor/dist/css/medium-editor.css'
import 'medium-editor/dist/css/themes/default.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import './assets/plugins/medium-editor-insert-plugin.min.css'

editor()
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
