const actions = {
  LOAD_CATEGORIES: 'LOAD_CATEGORIES',
  LOAD_CATEGORIES_SUCCESS: 'LOAD_CATEGORIES_SUCCESS',
  LOAD_CATEGORIES_ERROR: 'LOAD_CATEGORIES_ERROR',
  SAVE_CATEGORY: 'SAVE_CATEGORY',
  SAVE_CATEGORY_ERROR: 'SAVE_CATEGORY_ERROR',
  SAVE_CATEGORY_SUCCESS: 'SAVE_CATEGORY_SUCCESS',
  TOGGLE_CATEGORY_MODAL: 'TOGGLE_CATEGORY_MODAL',
  UPDATE_CATEGORY: 'UPDATE_CATEGORY',

  loadCategories: (data) => {
    return { type: actions.LOAD_CATEGORIES, payload: { data }, };
  },

  loadCategoriesSuccess: data => ({
    type: actions.LOAD_CATEGORIES_SUCCESS,
    payload: { data },
  }),

  loadCategoriesError: error => ({
    type: actions.LOAD_CATEGORIES_ERROR,
    payload: { error },
  }),

  toggleModal: (data = null) => ({
    type: actions.TOGGLE_CATEGORY_MODAL,
    payload: { data },
  }),

  saveCategory: (data, actionName = 'insert') => ({
    type: actions.SAVE_CATEGORY,
    payload: { data, actionName },
  }),

  saveCategorySuccess: () => ({
    type: actions.SAVE_CATEGORY_SUCCESS
  }),

  saveCategoryError: error => ({
    type: actions.SAVE_CATEGORY_ERROR,
    payload: { error },
  }),

  update: (data) => ({
    type: actions.UPDATE_CATEGORY,
    payload: { data },
  })
};
export default actions;
