import { all } from 'redux-saga/effects';
import authSagas from '@iso/redux/auth/saga';
import ecommerceSaga from '@iso/redux/ecommerce/saga';
import users from '@iso/redux/users/sagas';
import contacts from '@iso/redux/contacts/sagas';
import banners from '@iso/redux/banners/sagas';
import categories from '@iso/redux/categories/sagas';
import products from '@iso/redux/products/sagas';
import notifications from '@iso/redux/notifications/sagas';
import settings from '@iso/redux/settings/sagas';
import dashboard from '@iso/redux/dashboard/sagas';

export default function* rootSaga() {
  yield all([
    users(),
    contacts(),
    banners(),
    categories(),
    products(),
    notifications(),
    authSagas(),
    ecommerceSaga(),
    settings(),
    dashboard()
  ]);
}
