import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import api from '@iso/lib/services/api'
import toastr from 'toastr'

function* loadNotifications({ payload }) {
  try {
    var data = {}
    if (payload && payload.data) data = payload.data;
    
    let notificationData = yield call(api.getNotifications, data);
    if (notificationData && notificationData.data && notificationData.data.data && notificationData.data.data.getNotifications && notificationData.data.data.getNotifications.notifications) notificationData = notificationData.data.data.getNotifications
    else notificationData = []
    yield put(actions.loadNotificationsSuccess(notificationData));
  } catch (error) {
    yield put(actions.loadNotificationsError(error));
  }
}

function* saveNotification({ payload, }) {
  const { data, actionName } = payload;
  try {
    switch (actionName) {
      case 'delete':
        yield call(api.deleteNotification, data);
        toastr.success('Notification deleted successfully')
        break;
      default:
        yield call(api.saveNotification, data);
        toastr.success('Notification saved successfully')
        break;
    }
    yield put(actions.saveNotificationSuccess());
    yield put({ type: actions.LOAD_NOTIFICATIONS, payload: { data: { skip: 0, limit: 15 } } });
  } catch (error) {
    toastr.error((error && error.response && error.response.data && error.response.data.error) ? error.response.data.error : ('Error in notification ' + actionName))
    yield put(actions.saveNotificationError(error));
  }
}


export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_NOTIFICATIONS, loadNotifications),
    takeEvery(actions.SAVE_NOTIFICATION, saveNotification)
  ]);
}
