const actions = {
  LOAD_BANNERS: 'LOAD_BANNERS',
  LOAD_BANNERS_SUCCESS: 'LOAD_BANNERS_SUCCESS',
  LOAD_BANNERS_ERROR: 'LOAD_BANNERS_ERROR',
  SAVE_BANNER: 'SAVE_BANNER',
  SAVE_BANNER_ERROR: 'SAVE_BANNER_ERROR',
  SAVE_BANNER_SUCCESS: 'SAVE_BANNER_SUCCESS',
  TOGGLE_BANNER_MODAL: 'TOGGLE_BANNER_MODAL',
  UPDATE_BANNER: 'UPDATE_BANNER',

  loadBanners: (data) => {
    return { type: actions.LOAD_BANNERS, payload: { data }, };
  },

  loadBannersSuccess: data => ({
    type: actions.LOAD_BANNERS_SUCCESS,
    payload: { data },
  }),

  loadBannersError: error => ({
    type: actions.LOAD_BANNERS_ERROR,
    payload: { error },
  }),

  toggleModal: (data = null) => ({
    type: actions.TOGGLE_BANNER_MODAL,
    payload: { data },
  }),

  saveBanner: (data, actionName = 'insert') => ({
    type: actions.SAVE_BANNER,
    payload: { data, actionName },
  }),

  saveBannerSuccess: () => ({
    type: actions.SAVE_BANNER_SUCCESS
  }),

  saveBannerError: error => ({
    type: actions.SAVE_BANNER_ERROR,
    payload: { error },
  }),

  update: (data) => ({
    type: actions.UPDATE_BANNER,
    payload: { data },
  })
};
export default actions;
