import actions from './actions';

const initState = {
  isLoading: false,
  errorMessage: false,
  users: [],
  subscriptions: [],
  userMeasurements: [],
  userMeasurement: {},
  sessions: [],
  total: 0,
  modalActive: false,
  user: {},
  userDetail: {},
  measurements: []
};

export default function reducer(
  state = initState,
  { type, payload }
) {
  switch (type) {
    case actions.LOAD_USERS:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
    case actions.LOAD_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: payload.data.users ? payload.data.users : [],
        subscriptions: payload.data.subscriptions ? payload.data.subscriptions : [],
        sessions: payload.data.sessions ? payload.data.sessions : [],
        total: payload.data.total ? payload.data.total : 0,
        errorMessage: false,
      };
    case actions.LOAD_USERS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'There is a loading problem',
      };
    case actions.TOGGLE_USER_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        user: payload.data == null ? initState.user : payload.data,
      };
    case actions.USER_DETAIL:
      return {
        ...state,
        isLoading: true
      }
    case actions.USER_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userDetail: payload.data
      }
    case actions.USER_DETAIL_ERROR:
      return {
        ...state,
        isLoading: false
      }
    case actions.UPDATE_USER:
      return {
        ...state,
        user: payload.data,
      };
    case actions.SAVE_USER:
      return {
        ...state,
        isLoading: true
      };
    case actions.SAVE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: false,
      };
    case actions.SAVE_USER_ERROR:
      return {
        ...state,
        errorMessage: 'Error in save user',
        isLoading: false
      };
    case actions.APPROVE_USER:
      return {
        ...state,
        user: payload.data,
      };
    case actions.APPROVE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: false,
      };
    case actions.APPROVE_USER_ERROR:
      return {
        ...state,
        errorMessage: 'Error in approve user',
        isLoading: false
      };
    case actions.CHANGE_PASSWORD:
      return {
        ...state,
        user: payload.data,
      };
    case actions.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: false,
      };
    case actions.CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        errorMessage: payload.error,
        isLoading: false
      };
    case actions.LOAD_USER_MEASUREMENTS:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
    case actions.LOAD_USER_MEASUREMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userMeasurements: payload.data.userMeasurements ? payload.data.userMeasurements : [],
        measurements: payload.data.measurements ? payload.data.measurements : [],
        total: payload.data.total ? payload.data.total : 0,
        errorMessage: false,
      };
    case actions.LOAD_USER_MEASUREMENTS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'There is a loading problem',
      };
    case actions.SAVE_USER_MEASUREMENT:
      return {
        ...state,
        isLoading: true
      };
    case actions.SAVE_USER_MEASUREMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: false,
      };
    case actions.SAVE_USER_MEASUREMENT_ERROR:
      return {
        ...state,
        errorMessage: 'Error in save user measurement',
        isLoading: false
      };
    default:
      return state;
  }
}
